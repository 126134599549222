import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import { AxiosError } from 'axios'
import { DataFeedItemsOptions } from 'services/mingle/__generated__/Api'
import {
  getDataDiscoveries,
  getDataSubscriptions,
  getIndustryDiscoveries,
  getSupplierDiscoveries,
  getArticleById,
  getDataFeedItems,
} from './api'

import type { GetDataFeedItemsProps } from './api'

import queryKeys from './queryKeys'

const { dataFeedItems, articleById, newsAndMediaDataFeedItems } = queryKeys

export const useGetArticleById = (
  articleId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getArticleById>>,
    AxiosError,
    Awaited<ReturnType<typeof getArticleById>>,
    ReturnType<typeof articleById>
  >
) => {
  return useQuery(articleById(articleId), () => getArticleById({ articleId }), options)
}

export const useGetDataFeedItemsV2 = (props?: GetDataFeedItemsProps, enabled?: boolean) => {
  return useInfiniteQuery<
    Awaited<ReturnType<typeof getDataFeedItems>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataFeedItems>>,
    ReturnType<typeof dataFeedItems>
  >(
    dataFeedItems(props),
    ({ pageParam = 1 }) =>
      getDataFeedItems({
        ...props,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        props?.pageSize && lastPage.data.adverseMedia.length < props.pageSize
          ? undefined
          : (lastPage.page || 0) + 1,
      enabled,
    }
  )
}

export const useGetNewsAndMediaDataFeedItems = (
  props?: DataFeedItemsOptions,
  options?: {
    enabled?: boolean
    refetchInterval?: number
  }
) => {
  return useInfiniteQuery<
    Awaited<ReturnType<typeof getDataFeedItems>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataFeedItems>>,
    ReturnType<typeof newsAndMediaDataFeedItems>
  >(
    newsAndMediaDataFeedItems(props),
    ({ pageParam = 1 }) =>
      getDataFeedItems({
        ...props,
        page: pageParam,
      }),
    {
      ...options,
      getNextPageParam: (lastPage) =>
        props?.pageSize && lastPage.data.adverseMedia.length < props.pageSize
          ? undefined
          : (lastPage.page || 0) + 1,
    }
  )
}

export const useGetDataSubscriptions = (organizationSupplierId: string, enabled?: boolean) => {
  return useQuery(
    queryKeys.dataSubscriptions(organizationSupplierId),
    () => getDataSubscriptions(organizationSupplierId),
    {
      retry: true,
      staleTime: 500000,
      enabled,
    }
  )
}

export const useGetSupplierDiscoveries = (
  searchValues: { supplierName: string; supplierCountry?: string }[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(
    queryKeys.supplierDiscoveries(searchValues),
    () => getSupplierDiscoveries(searchValues),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetIndustryDiscoveries = (
  domains: string[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(queryKeys.industryDiscoveries(domains), () => getIndustryDiscoveries(domains), {
    ...options,
    retry: true,
    staleTime: 500000,
  })
}

export const useGetDataDiscoveries = (domains: string[], enabled?: boolean) => {
  return useQuery(queryKeys.dataDiscoveries(domains), () => getDataDiscoveries(domains), {
    retry: true,
    staleTime: 500000,
    enabled,
  })
}
