import { DataFeedItemsOptions } from 'services/mingle/__generated__/Api'

const dataSubscriptions = (supplierId?: string) => ['dataSubscriptions', supplierId] as const
const supplierDiscoveries = (searchValues: { supplierName: string; supplierCountry?: string }[]) =>
  ['supplierDiscoveries', searchValues] as const
const industryDiscoveries = (domains: string[]) => ['industryDiscoveries', domains] as const
const dataDiscoveries = (domains: string[]) => ['dataDiscoveries', domains] as const
const articleById = (articleId: string) => ['articleById', articleId] as const
const dataFeedItems = (props?: DataFeedItemsOptions) =>
  ['dataFeedItems', ...Object.values(props || {})] as const
const allNewsAndMediaDataFeedItems = ['newsAndMediaDataFeedItems'] as const
const newsAndMediaDataFeedItems = (props?: DataFeedItemsOptions) =>
  [...allNewsAndMediaDataFeedItems, ...Object.values(props || {})] as const

export default {
  dataSubscriptions,
  supplierDiscoveries,
  industryDiscoveries,
  dataDiscoveries,
  articleById,
  dataFeedItems,
  allNewsAndMediaDataFeedItems,
  newsAndMediaDataFeedItems,
} as const
