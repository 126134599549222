/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface NewsArticle {
  id: string
  title?: string | null
  language?: string | null
  languagePredicted?: string | null
  description?: string | null
  content?: string
  publisher?: string | null
  publishedAt?: string | null
  createdAt: string
  authors?: string[] | null
  url: string
  imageUrl?: string | null
  chunks: {
    index: number
    chunk: string
  }[]
  topics: {
    id: string
    articleId: string
    topic: string
    score: number
  }[]
  sentiment: {
    id: string
    label: 'positive' | 'negative' | 'neutral'
    score: number
    inspection: {
      average: {
        label: 'positive' | 'negative' | 'neutral'
        score: number
      }[]
      body: {
        label: 'positive' | 'negative' | 'neutral'
        score: number
      }[]
      description: {
        label: 'positive' | 'negative' | 'neutral'
        score: number
      }[]
      title: {
        label: 'positive' | 'negative' | 'neutral'
        score: number
      }[]
    }
  } | null
  ners?: {
    id: string
    word: string
    nerGroup: 'ORG' | 'LOC' | 'PER'
    count: number
    inspection: {
      entities: {
        word: string
        nerGroup: 'ORG' | 'LOC' | 'PER'
        count: number
      }[]
    } | null
  }[]
  nerSimilarities: {
    a: string
    b: string
    similarity: number
  }[]
  links: {
    url: string
    text: string | null
  }[]
  newsSource?: {
    id: string
    name?: string | null
    description?: string | null
    domain?: string | null
    logoUrl?: string | null
  }
  policyParameters?: {
    parameterId: string | null
    score: number
  }[]
}

export interface ArticleEntitySearchResult {
  articleId: string
  entity: string
}

export interface ArticleEntitySearchOptions {
  /** @minItems 1 */
  entities: string[]
  /** @default "ORG" */
  nerGroup?: 'ORG' | 'LOC' | 'PER'
  /** @default 0.9 */
  nerScoreMin?: number
}

export interface DataFeedItemAdverseMediaV2 {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    organizationSupplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    name: string | null
    headline: string | null
    articleSummary: string | null
  }
  article: {
    id: string
    title: string | null
    language: string | null
    languagePredicted: string | null
    description: string | null
    publisher: string | null
    publishedAt: string | null
    createdAt: string | null
    authors: string[] | null
    url: string
    imageUrl: string | null
    newsSource: {
      id: string
      name: string | null
      description: string | null
      domain: string | null
      language: string | null
      logoUrl: string | null
    }
  }
}

export interface DataFeedItemDomainV2 {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    organizationSupplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    website: string
    entityName: string | null
    websiteNameFound: string | null
    websiteSimilarityUrl: number
    websiteSimilarityNameFound: number
  }
}

export interface DataFeedItemSupplierAliasV2 {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    organizationSupplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    alias: string
  }
}

export interface DataFeedItemDataEntryV2 {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    organizationSupplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    dataSourceIds: string[]
    keyValues: {
      key: string
      rawValue: string
      parsedValue: string
      status:
        | 'REQUIRES_HUMAN_REVIEW'
        | 'HUMAN_REVIEWED'
        | 'AUTO_EXTRACTED'
        | 'HUMAN_CREATED'
        | 'NOT_FOUND'
    }[]
  }
}

export interface DataFeedItemClaimV2 {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    organizationSupplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    dataSourceId: string
  }
}

export interface DataFeedItemsResponseV2 {
  adverseMedia: DataFeedItemAdverseMediaV2[]
  domains: DataFeedItemDomainV2[]
  supplierAliases: DataFeedItemSupplierAliasV2[]
  dataEntries: DataFeedItemDataEntryV2[]
  claims: DataFeedItemClaimV2[]
}

export interface DataFeedItemsOptionsAdmin {
  organizationId: string
  supplierId?: string
  page?: number
  pageSize?: number
  order?: {
    by?: {
      adverseMedia?: 'itemCreatedAt' | 'articlePublishedAt'
    }
    direction?: 'asc' | 'desc'
  }
  filter?: {
    adverseMedia?: {
      parameters?: string[]
    }
  }
}

export interface DataFeedItemAdverseMedia {
  id: string
  confidence: number | null
  createdAt: string
  status: 'approved' | 'auto_approved' | 'in_review' | 'rejected' | null
  type:
    | 'adverse_media'
    | 'claim'
    | 'data_entry'
    | 'domain'
    | 'industry'
    | 'kpi'
    | 'statement'
    | 'supplier_alias'
    | null
  subscription: {
    id: string
    organizationId: string
    supplierId: string
    searchValue: string
    subscriptionType:
      | 'data'
      | 'industry'
      | 'news_and_media'
      | 'website'
      | 'global_db'
      | 'supplier_alias'
  } | null
  entities: {
    id: string
    entity: string | null
    entityNormalized: string | null
    nerScore: number | null
    similarityScore: number | null
  }[]
  parameters: {
    policyParameterId: string
    reason: string | null
  }[]
  childItemIds: string[]
  result: {
    name: string | null
    headline: string | null
    articleSummary: string | null
  }
  article: {
    id: string
    title: string | null
    language: string | null
    languagePredicted: string | null
    description: string | null
    publisher: string | null
    publishedAt: string | null
    createdAt: string | null
    authors: string[] | null
    url: string
    imageUrl: string | null
    newsSource: {
      id: string
      name: string | null
      description: string | null
      domain: string | null
      logoUrl: string | null
    }
    policyParameters: {
      score: number
      parameterId: string | null
    }[]
  }
}

export interface DataFeedItemsResponse {
  adverseMedia: DataFeedItemAdverseMedia[]
}

export interface DataFeedItemsOptions {
  supplierIds?: string[]
  supplierId?: string
  page?: number
  pageSize?: number
  order?: {
    by?: {
      adverseMedia?: 'itemCreatedAt' | 'articlePublishedAt'
    }
    direction?: 'asc' | 'desc'
  }
  filter?: {
    adverseMedia?: {
      parameters?: string[]
      includeRejected?: boolean
    }
  }
}

export interface OrganizationSupplier {
  id: string
  organizationId: string
  name: string
  aliases: string[]
  countryIso3Code: string | null
  createdAt: string
  createdBy: string | null
  status: string
  categories: {
    id: string
    name: string
  }[]
  industries: {
    code: string
    name: string
  }[]
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Mingle 💞
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  newsAndMedia = {
    /**
     * No description
     *
     * @tags News and Media
     * @name GetArticles
     * @request GET:/news-and-media/articles
     */
    getArticles: (
      query?: {
        deep?: boolean | null
        ORG?: string | string[]
        LOC?: string | string[]
        PER?: string | string[]
        publisher?: string | string[]
        sentiment?: 'positive' | 'negative' | 'neutral' | ('positive' | 'negative' | 'neutral')[]
        newsSourceUuid?: string | string[]
        inContent?: string
        term?: string
        from?: string | null
        to?: string | null
        dest?: 'desc' | 'asc'
        /** @default 20 */
        perPage?: number | null
        /** @default 1 */
        page?: number | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          pagination: {
            page: number
            pageSize: number
            nextPage: number | null
          }
          articles: {
            id: string
            title?: string | null
            language?: string | null
            description?: string | null
            content?: string
            publisher?: string | null
            publishedAt?: string | null
            createdAt: string
            authors?: string[] | null
            url: string
            imageUrl?: string | null
            topics: (
              | {
                  id: string
                  articleId: string
                  topic: string
                  score: number
                }
              | {
                  topic: string
                }
            )[]
            sentiment?:
              | {
                  id: string
                  articleId: string
                  label: 'positive' | 'negative' | 'neutral'
                  score: number
                  inspection: {
                    sentiments: {
                      label: 'positive' | 'negative' | 'neutral'
                      score: number
                    }[]
                  }
                }
              | null
              | {
                  label: 'positive' | 'negative' | 'neutral'
                }
            ners?: {
              id: string
              articleId: string
              word: string
              nerGroup: 'ORG' | 'LOC' | 'PER'
              count: number
              inspection: {
                entities: {
                  word: string
                  nerGroup: 'ORG' | 'LOC' | 'PER'
                  count: number
                }[]
              } | null
            }[]
            newsSource?: {
              id: string
              name?: string | null
              domain?: string | null
              logoUrl?: string | null
            }
            policyParameters?: {
              id?: string | null
              parameterId?: string | null
              parameterName?: string | null
            }[]
          }[]
        },
        any
      >({
        path: `/news-and-media/articles`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name NewsAndMediaGetArticlesById
     * @request GET:/news-and-media/articles/by-id
     */
    newsAndMediaGetArticlesById: (
      query: {
        uuid: string
      },
      params: RequestParams = {}
    ) =>
      this.request<NewsArticle, any>({
        path: `/news-and-media/articles/by-id`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name GetArticlesByUrl
     * @request GET:/news-and-media/articles/by-url
     */
    getArticlesByUrl: (
      query: {
        url: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          authors: string[] | null
          content: string
          createdAt: string
          description: string | null
          imageUrl: string | null
          language: string | null
          languagePredicted: string | null
          newsSourceId: string
          publishedAt: string | null
          publisher: string | null
          staticUrl: string
          tags: string[] | null
          title: string | null
          url: string
          id: string
        }[],
        any
      >({
        path: `/news-and-media/articles/by-url`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name GetArticlesSearchByEntities
     * @request POST:/news-and-media/articles/search-by-entities
     */
    getArticlesSearchByEntities: (data: ArticleEntitySearchOptions, params: RequestParams = {}) =>
      this.request<ArticleEntitySearchResult[], any>({
        path: `/news-and-media/articles/search-by-entities`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Note: there is a firm limit of 20 entities returned for this endpoint.
     *
     * @tags News and Media
     * @name GetEntities
     * @request GET:/news-and-media/entities
     */
    getEntities: (
      query?: {
        nerGroup?: 'ORG' | 'LOC' | 'PER'
        /** search entities by word */
        word?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          entities: {
            word: string
            nerGroup: 'ORG' | 'LOC' | 'PER'
          }[]
        },
        any
      >({
        path: `/news-and-media/entities`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name PostEntities
     * @request POST:/news-and-media/entities
     */
    postEntities: (
      data: {
        entities: {
          articleId: string
          count: number
          nerGroup: 'LOC' | 'ORG' | 'PER'
          inspection: string
          score: number
          word: string
        }[]
        entitySimilarities: {
          x: string
          y: string
          similarity: number
        }[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        }[],
        any
      >({
        path: `/news-and-media/entities`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name GetNewsSources
     * @request GET:/news-and-media/news-sources
     */
    getNewsSources: (
      query?: {
        domain?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          newsSources: {
            id: string
            domain: string | null
            name: string | null
            logoUrl: string | null
            language: string | null
          }[]
        },
        any
      >({
        path: `/news-and-media/news-sources`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Note: there is a firm limit of 20 publishers returned for this endpoint.
     *
     * @tags News and Media
     * @name GetListPublishers
     * @request GET:/news-and-media/publishers
     */
    getListPublishers: (
      query?: {
        searchPublisher?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          publishers: {
            publisher: string
          }[]
        },
        any
      >({
        path: `/news-and-media/publishers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name PostArticlesFull
     * @request POST:/news-and-media/articles-full
     */
    postArticlesFull: (
      data: {
        newsSourceDomain: string
        article: {
          authors?: string[]
          content: string
          description?: string
          imageUrl?: string
          language?: string
          languagePredicted?: string
          publisher?: string
          staticUrl: string
          tags?: string[]
          title?: string
          url: string
          publishedAt?: string
        }
        links: {
          url: string
          text?: string
        }[]
        entities: {
          count: number
          nerGroup: 'LOC' | 'ORG' | 'PER'
          inspection: string
          score: number
          word: string
        }[]
        entitySimilarities: {
          x: string
          y: string
          similarity: number
        }[]
        topics: {
          score: number
          topic: string
        }[]
        sentiment: {
          inspection: string
          label: 'negative' | 'neutral' | 'positive'
          score: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        },
        any
      >({
        path: `/news-and-media/articles-full`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name PostDocuments
     * @request POST:/news-and-media/documents
     */
    postDocuments: (
      data: {
        documentTitle?: string
        documentFileName?: string
        documentType?: any
        articleUrl?: string
        fileChecksum?: string
        pages: {
          chunk: string
        }[][]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        },
        any
      >({
        path: `/news-and-media/documents`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags News and Media
     * @name UpdateArticlePublishedAtByUrl
     * @request PUT:/news-and-media/articles/update-article-published-at-by-url
     */
    updateArticlePublishedAtByUrl: (
      data: {
        url: string
        publishedAt: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        },
        any
      >({
        path: `/news-and-media/articles/update-article-published-at-by-url`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  dataFeed = {
    /**
     * No description
     *
     * @tags Data feed
     * @name PostSubscriptions
     * @request POST:/data-feed/subscriptions
     */
    postSubscriptions: (
      data: {
        organizationId: string
        organizationSupplierId: string
        subscriptionType:
          | 'data'
          | 'industry'
          | 'news_and_media'
          | 'website'
          | 'global_db'
          | 'supplier_alias'
        searchValue: string
      }[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        }[],
        any
      >({
        path: `/data-feed/subscriptions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name DeleteSubscriptions
     * @request POST:/data-feed/subscriptions/delete
     */
    deleteSubscriptions: (
      data: {
        organizationId: string
        supplierId: string
        subscriptionType:
          | 'data'
          | 'industry'
          | 'news_and_media'
          | 'website'
          | 'global_db'
          | 'supplier_alias'
        searchValue: string
      }[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        }[],
        any
      >({
        path: `/data-feed/subscriptions/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name PostItemsFull
     * @request POST:/data-feed/items-full
     */
    postItemsFull: (
      data: {
        item: {
          confidence: number
          parentItemUuid?: string
          result: string
          resultUniqueHash?: string
          status: 'approved' | 'auto_approved' | 'in_review' | 'rejected'
          type:
            | 'claim'
            | 'data_entry'
            | 'domain'
            | 'industry'
            | 'kpi'
            | 'statement'
            | 'adverse_media'
        }
        subscription?: {
          organizationId: string
          organizationSupplierId: string
          subscriptionType: 'data' | 'industry' | 'news_and_media' | 'website'
          searchValue: string
        }
        articleId?: string
        file?: {
          s3Bucket: string
          s3Key: string
          organizationId: string
          originalName: string
          dataOrigin: 'website' | 'third_party' | 'engagement' | 'customer'
        }
        entities: {
          entity: string
          entityNormalized: string
          nerScore?: number
          similarityScore?: number
        }[]
        parameters: {
          parameterId: string
          reason?: string
        }[]
      }[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        }[],
        any
      >({
        path: `/data-feed/items-full`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name PatchItems
     * @request PATCH:/data-feed/items
     */
    patchItems: (
      data: {
        id: string
        result?: any
      }[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
        }[],
        any
      >({
        path: `/data-feed/items`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name GetDataFeedItems
     * @request POST:/data-feed/items
     */
    getDataFeedItems: (data: DataFeedItemsOptionsAdmin, params: RequestParams = {}) =>
      this.request<DataFeedItemsResponseV2, any>({
        path: `/data-feed/items`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name PostItemRelations
     * @request POST:/data-feed/items/relations/post
     */
    postItemRelations: (
      data: {
        childId: string
        parentId: string
      }[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          childId: string
          parentId: string
        }[],
        any
      >({
        path: `/data-feed/items/relations/post`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Data feed
     * @name PostSupplierAliasItem
     * @request POST:/data-feed/supplier-alias-items
     */
    postSupplierAliasItem: (
      data: {
        item: {
          result: {
            alias: string
          }
        }
        subscription: {
          organizationId: string
          organizationSupplierId: string
          searchValue: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          subscription: {
            id: string
          }
          item: {
            id: string
          }
        },
        any
      >({
        path: `/data-feed/supplier-alias-items`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  app = {
    /**
     * @description getDataFeedItemsByOrganization
     *
     * @tags App
     * @name GetDataFeedItemsByOrganization
     * @request POST:/app/data-feed/items
     */
    getDataFeedItemsByOrganization: (data: DataFeedItemsOptions, params: RequestParams = {}) =>
      this.request<DataFeedItemsResponse, any>({
        path: `/app/data-feed/items`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name GetDataFeedItemsByOrganizationV2
     * @request POST:/app/data-feed/itemsv2
     */
    getDataFeedItemsByOrganizationV2: (
      data: DataFeedItemsOptions & {
        supplierIds?: string[]
        supplierId?: string
        page?: number
        pageSize?: number
        order?: {
          by?: {
            adverseMedia?: 'itemCreatedAt' | 'articlePublishedAt'
          }
          direction?: 'asc' | 'desc'
        }
        filter?: {
          id?: string
          adverseMedia?: {
            searchArticle?: string
            searchEvent?: string
            parameters?: string[]
          }
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<DataFeedItemsResponseV2, any>({
        path: `/app/data-feed/itemsv2`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name UpdateDataFeedItemStatus
     * @request PUT:/app/data-feed/items/{id}/status
     */
    updateDataFeedItemStatus: (
      id: string,
      data: {
        status: 'approved' | 'rejected'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, any>({
        path: `/app/data-feed/items/${id}/status`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name GetArticlesById
     * @request GET:/app/news-and-media/articles
     */
    getArticlesById: (
      query: {
        id: string
      },
      params: RequestParams = {}
    ) =>
      this.request<NewsArticle, any>({
        path: `/app/news-and-media/articles`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description deleteSupplierSubscription
     *
     * @tags App
     * @name DeleteSupplierSubscription
     * @request POST:/app/supplier/{supplierId}/subscriptions/delete
     */
    deleteSupplierSubscription: (
      supplierId: string,
      data: {
        subscriptionType:
          | 'data'
          | 'industry'
          | 'news_and_media'
          | 'website'
          | 'global_db'
          | 'supplier_alias'
        searchValue: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          subscriptionId?: string
        },
        any
      >({
        path: `/app/supplier/${supplierId}/subscriptions/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description getSupplierSubscriptionBySubscriptionId
     *
     * @tags App
     * @name GetSupplierSubscriptionBySubscriptionId
     * @request GET:/app/supplier/{supplierId}/subscriptions/{subscriptionId}
     */
    getSupplierSubscriptionBySubscriptionId: (
      supplierId: string,
      subscriptionId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          id: string
          organizationId: string
          organizationSupplierId: string
          subscriptionType: string
          searchValue: string
        },
        any
      >({
        path: `/app/supplier/${supplierId}/subscriptions/${subscriptionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description deleteSupplierSubscriptionByUuid
     *
     * @tags App
     * @name DeleteSupplierSubscriptionByUuid
     * @request DELETE:/app/supplier/{supplierId}/subscriptions/{subscriptionId}
     */
    deleteSupplierSubscriptionByUuid: (
      supplierId: string,
      subscriptionId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          subscriptionId?: string
        },
        any
      >({
        path: `/app/supplier/${supplierId}/subscriptions/${subscriptionId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description getSupplierSubscriptions
     *
     * @tags App
     * @name GetSupplierSubscriptions
     * @request GET:/app/supplier/{supplierId}/subscriptions
     */
    getSupplierSubscriptions: (
      supplierId: string,
      query?: {
        searchValue?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          items: {
            id: string
            organizationSupplierId: string
            organizationId: string
            subscriptionType: string
            searchValue: string
          }[]
        },
        any
      >({
        path: `/app/supplier/${supplierId}/subscriptions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  organizationSuppliers = {
    /**
     * @description getOrganizationSupplierById
     *
     * @tags Organization suppliers
     * @name GetOrganizationSupplierById
     * @request GET:/organization-suppliers/{supplierId}
     */
    getOrganizationSupplierById: (supplierId: string, params: RequestParams = {}) =>
      this.request<OrganizationSupplier, void>({
        path: `/organization-suppliers/${supplierId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
