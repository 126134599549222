import { getPerformanceColors } from 'utils'
import {
  Box,
  BoxProps,
  Typography,
  useTheme,
  TypographyVariant,
  Skeleton,
  Theme,
  SxProps,
  alpha,
} from '@mui/material'
import GaugeComponent from 'react-gauge-component'
import { PieChart, Pie, Cell } from 'recharts'

const gaugeZeroLength = 0.09
const bracketLength = (1 - gaugeZeroLength) / 6

export const getGaugeValue = (val?: number) => {
  const minPositiveValue = 55
  const minNegativeValue = 45.5
  if (val !== undefined && val > 0) {
    const newVal = 53.5 + val / 2
    return newVal > minPositiveValue ? (newVal > 100 && 100) || newVal : minPositiveValue
  }
  if (val !== undefined && val < 0) {
    const newVal = 48.5 + val / 2
    return newVal > minNegativeValue ? minNegativeValue : newVal
  }

  return 51.5
}

const createGaugeColorArray = (val?: number) => {
  const newVal = val === undefined ? 0 : val
  return [
    alpha('#FF4500', newVal < -2 && newVal >= -3 ? 1 : 0.1),
    alpha('#FFA500', newVal < -1 && newVal >= -2 ? 1 : 0.1),
    alpha('#FFD700', newVal < 0 && newVal >= -1 ? 1 : 0.1),
    alpha('#a1a1a1', newVal === 0 ? 1 : 0.1),
    alpha('#ADFF2F', newVal > 0 && newVal <= 1 ? 1 : 0.1),
    alpha('#32CD32', newVal > 1 && newVal <= 2 ? 1 : 0.1),
    alpha('#008000', newVal > 2 && newVal <= 3 ? 1 : 0.1),
  ]
}

interface Props extends BoxProps {
  value?: number
  size?: number
  typographyVariant?: TypographyVariant
  loading?: boolean
  /** show the donut with 5 brackets, used for performance */
  withBrackets?: boolean
  withHoverContainer?: boolean
  /** if reversed colors, a low number is considered "positive" */
  reversedColors?: boolean
  centerText?: string
  staticColor?: boolean
  /** TODO clean up */
  staticColorString?: string
  whiteBackground?: boolean
  fontSize?: number
  centerTextSx?: SxProps<Theme>
  gaugeMarginTop?: number
  roundToNearestInteger?: boolean
}

const Donut = ({
  value,
  typographyVariant = 'body2',
  loading,
  size = 40,
  withBrackets = true,
  withHoverContainer = true,
  reversedColors,
  centerText,
  staticColor,
  staticColorString,
  whiteBackground,
  fontSize,
  centerTextSx = {
    pt: '26px',
  },
  gaugeMarginTop = -23,
  roundToNearestInteger,
  ...rest
}: Props) => {
  const theme = useTheme()

  const valueAsPercentage =
    value !== undefined && withBrackets ? Math.min(Math.max(Math.ceil(value) * 20, 0), 100) : value

  const pathColor =
    staticColorString || getPerformanceColors(valueAsPercentage, reversedColors, staticColor)

  const donutData = (value !== undefined &&
    value === 0 && [
      { name: '1', value: 0 },
      { name: '2', value: 100 },
    ]) ||
    (value !== undefined && value === 100 && [{ name: '1', value: 100 }]) || [
      { name: '1', value },
      { name: '2', value: value !== undefined ? 100 - value + 0.01 : 0 },
    ]

  const gaugeValue = getGaugeValue(value)

  return withBrackets ? (
    <Box
      sx={{
        ...rest.sx,
        position: 'relative',
      }}
      {...rest}
      style={{
        height: size,
        width: size,
        maxWidth: size,
        maxHeight: size,
      }}
    >
      {loading ? (
        <Skeleton variant="circular" width={size} height={size} />
      ) : (
        <Box>
          <Typography
            variant={typographyVariant}
            sx={{
              fontWeight: 600,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              lineHeight: 0,
              zIndex: 9,
              fontSize: fontSize ? `${fontSize}px !important` : undefined,
              ...centerTextSx,
            }}
            color="textPrimary"
          >
            {value === undefined ? (
              '0'
            ) : (
              <Box component="span">
                {centerText}
                {!centerText && withBrackets
                  ? value
                  : !centerText &&
                    `${value > 0 && value < 1 ? '<1' : Math.round(value * 10) / 10}%`}
              </Box>
            )}
          </Typography>
          <GaugeComponent
            value={gaugeValue}
            type="radial"
            id="gauge-component"
            style={{
              width: size,
              height: size,
              marginTop: gaugeMarginTop,
            }}
            labels={{
              valueLabel: {
                hide: true,
              },
              tickLabels: {
                hideMinMax: true,
              },
            }}
            arc={{
              cornerRadius: 0,
              subArcs: [
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: gaugeZeroLength,
                  limit: 0,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
              ],
              padding: 0.02,
              width: 0.16,
              colorArray: createGaugeColorArray(value),
            }}
            pointer={{
              animate: false,
              elastic: false,
              animationDelay: 0,
              type: 'arrow',
              color: '#edddca',
              baseColor: '#000',
              width: 0,
            }}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        ...rest.sx,
        position: 'relative',
      }}
      {...rest}
      style={{
        height: size === 40 ? 46 : size,
        width: size === 40 ? 46 : size,
        maxWidth: size === 40 ? 46 : size,
        maxHeight: size === 40 ? 46 : size,
      }}
    >
      {loading ? (
        <Skeleton
          variant="circular"
          width={size === 40 ? 46 : size}
          height={size === 40 ? 46 : size}
        />
      ) : (
        <>
          <Typography
            variant={typographyVariant}
            sx={{
              fontWeight: 600,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              lineHeight: 0,
              zIndex: 9,
              fontSize: fontSize ? `${fontSize}px !important` : undefined,
            }}
            color="textPrimary"
          >
            <Box component="span">
              {centerText ||
                `${
                  valueAsPercentage !== undefined && valueAsPercentage > 0 && valueAsPercentage < 1
                    ? '<1'
                    : (valueAsPercentage && Math.round(valueAsPercentage * 10) / 10) ||
                      valueAsPercentage
                }%`}
            </Box>
          </Typography>
          <div
            style={{
              /** apparently RECHARTS fills up the donut from right to left with no props to turn direction. Super weird? */
              transform: 'scale(-1, 1)',
            }}
          >
            <PieChart
              width={size === 40 ? 46 : size}
              height={size === 40 ? 46 : size}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            >
              <Pie
                data={donutData}
                cx="50%"
                cy="50%"
                innerRadius="69%"
                startAngle={-268}
                endAngle={92}
                paddingAngle={0}
                dataKey="value"
                isAnimationActive={false}
              >
                {donutData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    stroke=""
                    fill={
                      (index === 0 && pathColor) ||
                      (whiteBackground && theme.palette.common.white) ||
                      theme.palette.background.default
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </>
      )}
    </Box>
  )
}

export default Donut
