import { tokenAxiosInstance } from 'services/apiClient'
import mingle from 'services/mingle'
import { DataFeedItemsOptions } from 'services/mingle/__generated__/Api'
import { SupplierDiscoveryItem } from './model/SupplierDiscoveryItem'
import { IndustryDiscoveryItem } from './model/IndustryDiscoveryItem'
import { DataDiscoveryItem } from './model/DataDiscoveryItem'
// import { type RequestBody as PostDataFeedSupplierAliasProps } from '../../../netlify/functions/minglePostDataFeedSupplierAliasItem/minglePostDataFeedSupplierAliasItem'

const baseURL = '/.netlify/functions'

export enum SubscriptionType {
  WEBSITE = 'WEBSITE',
  INDUSTRY = 'INDUSTRY',
  DATA = 'DATA',
  GLOBAL_DB = 'GLOBAL_DB',
  NEWS_AND_MEDIA = 'NEWS_AND_MEDIA',
  SUPPLIER_ALIAS = 'SUPPLIER_ALIAS',
}

export interface DataSubscription {
  organizationSupplierId: string
  subscriptionType: SubscriptionType
  searchValue: string
}

export interface PostDataFeedSupplierAliasProps {
  supplierName: string
  subscription: {
    organizationId: string
    supplierId: string
  }
}

export type GetDataFeedItemsProps = Parameters<
  typeof mingle.app.getDataFeedItemsByOrganizationV2
>[0]
export const getDataFeedItems = async ({
  supplierId,
  supplierIds,
  page,
  pageSize,
  order,
  filter,
}: DataFeedItemsOptions) => {
  const { data } = await mingle.app.getDataFeedItemsByOrganizationV2({
    supplierId,
    supplierIds,
    page,
    pageSize,
    order,
    filter,
  })
  return {
    page,
    data,
  }
}

export const getSupplierDataFeedItems = async ({
  supplierId,
  page,
  pageSize,
  order,
  filter,
}: DataFeedItemsOptions) => {
  const { data } = await mingle.app.getDataFeedItemsByOrganization({
    supplierId: supplierId || undefined,
    page,
    pageSize,
    order,
    filter,
  })
  return {
    page,
    data,
  }
}

export const getArticleById = async ({ articleId }: { articleId: string }) => {
  const { data } = await mingle.app.getArticlesById({ id: articleId })
  return data
}

export const getDataSubscriptions = async (organizationSupplierId: string) => {
  const { data: response } = await tokenAxiosInstance.get<{ items: DataSubscription[] }>(
    '/getDataSubscriptions',
    {
      baseURL,
      params: { organizationSupplierId },
    }
  )

  return response
}

export const deleteDataSubscription = async (subscription: DataSubscription) => {
  const { data: response } = await tokenAxiosInstance.post(
    '/deleteDataSubscription',
    subscription,
    { baseURL }
  )

  return response
}

export const createDataSubscription = async (subscription: DataSubscription) => {
  const { data: response } = await tokenAxiosInstance.post(
    '/createDataSubscription',
    subscription,
    { baseURL }
  )

  return response
}

export const getSupplierDiscoveries = async (
  searchValues: { supplierName: string; supplierCountry?: string }[]
) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: SupplierDiscoveryItem[] }>(
    '/getSupplierDiscoveries',
    { searchValues },
    { baseURL }
  )

  return response
}

export const getIndustryDiscoveries = async (domains: string[]) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: IndustryDiscoveryItem[] }>(
    '/getIndustryDiscoveries',
    { domains },
    { baseURL }
  )

  return response
}

export const getDataDiscoveries = async (domains: string[]) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: DataDiscoveryItem[] }>(
    '/getDataDiscoveries',
    { domains },
    { baseURL }
  )

  return response
}

export const createDataFeedSupplierAliasItem = async (props: PostDataFeedSupplierAliasProps) => {
  const { data: response } = await tokenAxiosInstance.post(
    // Note that a suggested alias is generated by the promptGetCleanedCompanyName function in the netlify function for this endpoint, to create cleaned alias data feed
    '/minglePostDataFeedSupplierAliasItem',
    props,
    {
      baseURL,
    }
  )

  return response
}

type UpdateDataFeedItemStatusParams = Parameters<typeof mingle.app.updateDataFeedItemStatus>
export const updateDataFeedItemStatus = async (props: {
  id: string
  status: UpdateDataFeedItemStatusParams[1]['status']
}) => {
  await mingle.app.updateDataFeedItemStatus(props.id, { status: props.status })

  return {}
}
